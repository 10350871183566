.hero {
  .jumbotron {
    background-image: url("../assets/winter-view.jpg");
    background-size: cover;
    background-position: center;

    .logo {
      max-width: 100%;
    }
  }
}
